<template>
  <div class="home">
    <div id="lowLive" style="width: 100%; height: 190px"></div>
  </div>
</template>

<script>
/* eslint-disable */
import HWLLSPlayer from "HWLLSPlayer";

export default {
  name: "Home",
  data() {
    return {};
  },
  props: {
    // 内容数据
    pullStreamUrl: {
      type: String,
      default: "",
    },
  },
  components: {},
  methods: {
    goBack() {
      this.$router.push({
        path: "/",
      });
    },
    async isBrowserSupport() {
      let check = false;
      try {
        check = await HWLLSPlayer.checkSystemRequirements();
      } catch (error) {
        console.error(
          `check browser isSupport error: ${error.getCode()} - ${error.getMsg()}`
        );
        if (error.getCode && error.getCode() === 50000006) {
          let commonClient = HWLLSPlayer.createClient("flv"); // "hls"
          await commonClient.startPlay(url, options);
        }
      }
      return check;
    },
    async startPlay() {
      let client = HWLLSPlayer.createClient("webrtc");
      const url = `webrtc://lll-pull.gtrmt.cn/live/${this.pullStreamUrl}`;
      const options = { elementId: "lowLive" };
      try {
        await client.startPlay(url, options);
        console.log("startPlay success");
      } catch (error) {
        console.log("startPlay fail", error);
        if (error.getCode && error.getCode() === 50000021) {
          console.log("播放失败1");
        }
      }
      client.on("video-start", () => {
        // console.log("播放成功-video");
        // console.log("video-start");
      });
      client.on("audio-start", () => {
        // console.log("播放成功-audio");
        // console.log("audio-start");
      });
      client.on("Error", (error) => {
        if (error.errCode === 50000007 || error.errCode === 50000022) {
          console.log("播放失败-low");
        }
      });
    },
    //   stopPlay() {
    //  try {
    //    client.stopPlay()
    //    console.log('stopPlay success')
    //  } catch (error) {
    //    console.log('stopPlay fail', error)
    //  }
  },

  mounted() {},
  async destroyed() {},
  created() {
    this.isBrowserSupport();
    this.startPlay();
  },
  beforeCreate() {},
};
</script>
<style></style>
