<style scoped>
.main-containers {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background: rgba(248 249 255); */
  background: rgba(0, 0, 0, 1);
  /* background: rgba(255, 255, 255, 0.5); */
  position: relative;
}
.msgbox {
  position: absolute;
  width: 100vw;
  height: 60px;
  left: 0;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  /* border: 1px solid #f00; */
  display: grid;
  grid-template-columns: 1fr 60px;
  padding: 10px 15px;
}
.inputstyle {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  /* background: rgba(0, 0, 0, 0.3); */
  position: relative;
}
.inputstyle input {
  border-radius: 20px;
  height: 40px;
  background: rgba(0, 0, 0, 0);
  /* background: rgba(255, 255, 255, 0.8); */
  border: none;
  outline: none;
  /* color: rgb(221, 217, 218); */
  /* color: rgb(255, 255, 255); */
  font-size: 16px;
  padding: 0 15px;
}
.inputstyle input::-webkit-input-placeholder {
  /* color: rgb(252, 250, 251); */
}
.signin {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgba(255, 255, 255, 0.8);
  border: none;
  display: block;
  margin: 0 auto;
  font-size: 20px;
}
.send {
  /* color: #fff; */
  color: #555;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 10px;
}
.danmubox {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100vw;
  height: auto;
  max-height: 30vh;
  /* border: 1px solid #0f0; */
  padding-left: 15px;
  padding-right: 30px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  overflow-y: scroll;
  transform: 0.5s;
}
.danmubox::-webkit-scrollbar {
  background-color: transparent;
}
.danmudetail {
  display: inline-block;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  padding: 0 10px;
  border-radius: 13px;
  margin: 2px 0;
}
.close {
  position: absolute;
  top: 10px;
  right: 13px;
  line-height: 30px;
  font-size: 34px;
  color: #fff;

  /* color: rgba(0, 0, 0, 0.3); */
}
.zhe {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  -webkit-filter: blur(60px);
  filter: blur(60px);
}
.changeLive {
  position: absolute;
  height: 40px;
  border-radius: 20px;
  top: 10px;
  right: 50px;
  z-index: 9;
  line-height: 40px;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 15px 0 15px;
  text-align: center;
}
.topleft {
  position: absolute;
  /* width: 100px; */
  height: 40px;
  border-radius: 20px;
  top: 10px;
  left: 10px;
  z-index: 9;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgba(255, 255, 255, 0.8);
  padding: 0 15px 0 0;
}
.topleft > img {
  display: inline-block;
  vertical-align: top;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: 3px;
}
.topleft > div {
  display: inline-block;
  margin-left: 10px;
}
.topleft > div > span.name {
  display: block;
  line-height: 30px;
  /* color: #fff; */
  font-size: 16px;
}
.topleft > div > span.sign {
  display: block;
  line-height: 5px;
  font-size: 10px;
  transform: scale(0.8);
  margin-left: -6px;
  margin-top: -2px;
  /* color: #fff; */
}
.zhe img {
  width: 300vh;
  height: 300vh;
  margin-top: -100vh;
  margin-left: -126vh;
}
.likemove {
  font-size: 22px;
  position: absolute;
  top: 0px;
  left: 19px;
}
#like {
  position: relative;
}
</style>

<template>
  <div class="main-containers">
    <!-- <div
      class="zhe"
      :style="`background:url('${live.avatar}');background-size:300vh 300vh;background-position:170vh 200vh;`"
    ></div> -->
    <div class="video" style="margin-top: 100px">
      <!-- {{ live.pullUrl }} -->
      <!-- pullStreamUrl="http://zjonline.gtrmt.cn/live/gtrmt.m3u8" -->
      <template v-if="now === '标准'">
        <liveXgHlsCmp :pullStreamUrl="live.pullUrl"></liveXgHlsCmp>
      </template>
      <template v-if="now === '低延迟'">
        <liveDetail :pullStreamUrl="live.streamName"></liveDetail>
      </template>
      <!-- <liveHlsCmp :pullStreamUrl="live.pullUrl"></liveHlsCmp> -->
    </div>
    <div class="topleft">
      <img src="/img/logo.jpeg" alt="" />
      <div>
        <!-- <span class="name">{{ live.userName }}</span> -->
        <span class="name">融媒体中心</span>
        <span class="sign"
          >{{
            likenum > 10000 ? (likenum / 10000).toFixed(1) + "W" : likenum
          }}本场点赞</span
        >
      </div>
    </div>
    <div class="changeLive" @click="changeNow()">
      <template v-if="now === '标准'">低延迟</template>
      <template v-if="now === '低延迟'">标准</template>
    </div>
    <div v-if="!isFocus" @click="backlist" class="close">×</div>
    <div class="danmubox" id="danmubox">
      <div v-for="(item, index) in danmu" :key="index">
        <div class="danmudetail">
          <span style="color: rgb(167, 224, 240)">{{ item.name }}:</span>
          <span>{{ item.content }}</span>
        </div>
      </div>
    </div>
    <div class="msgbox">
      <div class="inputstyle">
        <input
          style="width: calc(100% - 30px)"
          @blur="onBlur"
          @focus="onFocus"
          v-model="inputext"
          type="text"
          placeholder="说点什么..."
        />
        <i
          @click="sendMsg"
          class="fa fa-arrow-right send"
          aria-hidden="true"
        ></i>
      </div>
      <div @click="signlive" id="like">
        <!-- 点赞弹出效果 -->
        <button class="signin">👍</button>
        <!-- <div @click="signlive" class="likemove">👍</div> -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
// import liveXgFlvCmp from "../../widgets/liveXgFlvCmp.vue";
import liveXgHlsCmp from "../../widgets/liveXgHlsCmp";
// import liveHlsCmp from "../../widgets/liveHlsCmp";
import liveDetail from "../../widgets/noliveDetail";
import { wxConPost } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import moment from "moment";

import _ from "lodash";

const debug = require("debug")("@main");

export default {
  name: "LiveList",
  data() {
    return {
      inputext: "",
      danmu: [],
      live: {},
      userid: "",
      lastDanmuId: "",
      lastTime: new Date().getTime(),
      setIntervalObj: {},
      waitShowDanmu: [],
      intervalShowDanMu: {},
      isFocus: false,
      likenum: 0,
      signid: 0,
      now: "标准",
    };
  },
  computed: {},
  methods: {
    // onBlur
    onBlur() {
      this.isFocus = false;
    },
    // onFocus
    onFocus() {
      this.isFocus = true;
    },
    // 点赞效果
    signshow() {
      this.signid++;
      let like = document.getElementById("like");
      like.innerHTML += `<div style="font-size: 22px;position: absolute;top: 0px;left: 19px;transition: 1s;opacity: 1;" id="likemove${this.signid}">👍</div>`;
      setTimeout(() => {
        document.getElementById(`likemove${this.signid}`).style =
          "font-size: 22px;position: absolute;top:-70px;left: 19px;transition: 1s;opacity: 0;";
      }, 10);
      setTimeout(() => {
        like.removeChild(like.childNodes[1]);
      }, 1000);
    },
    // 直播点赞
    async signlive() {
      this.likenum++;
      this.signshow();
      await wxConPost("/services/gttt-live-mgt/deptLive/sendLike", {
        liveid: this.live.liveId,
      });
    },
    changeNow() {
      if (this.now === "标准") {
        this.now = "低延迟";
      } else if (this.now === "低延迟") {
        this.now = "标准";
      }
    },
    // 查询最新的弹幕消息
    async findNewDanMu() {
      const newDanMu = await wxConPost(
        "/services/gttt-live-mgt/deptLive/getBarrage",
        {
          liveid: this.live.liveId,
          lastMsgId: this.lastDanmuId,
          finishTime: this.lastTime,
        }
      );
      // 新弹幕消息 去除之前旧的循环
      if (this.intervalShowDanMu) {
        clearInterval(this.intervalShowDanMu);
      }
      // 如果有点赞数量
      if (newDanMu.likeNum) {
        this.likenum = newDanMu.likeNum;
      }
      // 如果弹幕消息存在  加入显示队列
      if (newDanMu.ret) {
        this.waitShowDanmu = this.waitShowDanmu.concat(newDanMu.ret);
        // 循环滚动弹幕信息
        this.intervalShowDanMu = setInterval(() => {
          // 如果有弹幕就滚动显示
          if (this.waitShowDanmu.length > 0) {
            this.lastTime = this.waitShowDanmu[0].createTime;
            if (this.waitShowDanmu[0].userid !== this.userid) {
              this.danmu.push({
                name: this.waitShowDanmu[0].name,
                content: this.waitShowDanmu[0].msg,
              });
              this.waitShowDanmu.shift();
              setTimeout(() => {
                this.scrollmsg();
              }, 10);
            } else {
              this.waitShowDanmu.shift();
            }
          } else {
            // 没有弹幕停止
            clearInterval(this.intervalShowDanMu);
          }
        }, 200);
      }
      // for (const item of newDanMu.ret) {
      //   this.lastDanmuId = item.id;
      //   if (item.userid !== this.userid) {
      //     this.danmu.push({
      //       name: item.name,
      //       content: item.msg,
      //     });
      //     setTimeout(() => {
      //       this.scrollmsg();
      //     }, 10);
      //     setTimeout(() => {}, 200);
      //   }
      // }
    },
    backlist() {
      // this.$router.replace({ path: "/liveList" });
      this.$router.back(-1);
    },
    // 发送消息
    async sendMsg() {
      if (this.inputext) {
        const result = await wxConPost(
          "/services/gttt-live-mgt/deptLive/sendBarrage",
          {
            liveid: this.live.liveId,
            msg: this.inputext,
          }
        );
        if (result.ret == "ok") {
          this.danmu.push({
            name: result.name,
            content: this.inputext,
          });
          this.inputext = "";
          setTimeout(() => {
            this.scrollmsg();
          }, 10);
        }
      }
    },
    scrollmsg() {
      const height = document.getElementById("danmubox").scrollHeight;
      document.getElementById("danmubox").scrollTop = height;
    },
    async getUserData() {
      try {
        const result = await wxConPost(
          "/services/gttt-user-mgt/wx/getUserData",
          {}
        );
        // 通知数据获取成功
        return result;
      } catch (e) {
        return {};
      }
    },
    // 上报到达直播间
    async reportArrive() {
      const result = await wxConPost(
        "/services/gttt-live-mgt/deptLive/joinRoom",
        {
          liveid: this.live.liveId,
        }
      );
      // 上报失败  5秒后重新上报
      if (result.ret !== "ok") {
        setTimeout(() => {
          this.reportArrive();
        }, 5000);
      }
    },
  },
  components: { liveXgHlsCmp, liveDetail },
  async created() {
    this.live = this.$route.query;
    console.log(this.live);
    this.$forceUpdate();
    wxLoginApi.onReady(async () => {
      const userInfo = await this.getUserData();
      this.userid = userInfo.userid;
      // 检查当前登陆人是否有观看直播权限
      const result = await wxConPost(
        "/services/gttt-live-mgt/deptLive/checkUserLive",
        { liveid: this.live.liveId }
      );
      if (result.ret !== "ok") {
        this.$router.replace({ path: "/" });
      }
      this.reportArrive();
      this.findNewDanMu();
    });
    this.setIntervalObj = setInterval(() => {
      this.findNewDanMu();
    }, 5000);
  },
  beforeCreate() {
    document.title = "直播详情";
  },
  destroyed() {
    clearInterval(this.setIntervalObj);
    debug("destroyed");
  },
};
</script>
